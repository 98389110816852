
import { Vue, Component } from "vue-property-decorator";
import QrcodeVue from 'qrcode.vue';

@Component({
  name: "QrGenerate",
  components: { QrcodeVue }
})
export default class QrGenerate extends Vue {

  qrCodeUrl: any = "";
  plateNumber: any = "";


  size: number = 350;

  mounted() {

    const urlValue = this.$route.query.value;

    this.plateNumber = this.$route.query.value;
    this.qrCodeUrl = `${window.location.origin}/qrbooking?value=${this.$route.query.value}`;
    //
    // this.$nextTick(() => {
    //   this.addTextAndImageToCanvas();
    // });

  }

  addTextAndImageToCanvas() {
    // const divElement = document.getElementById('qrcode-wrapper');
    // const qrCanvas = divElement.querySelector('canvas');
    // const finalCanvas = document.getElementById('finalCanvas');
    // const ctx = finalCanvas.getContext('2d');
    //
    // // Draw QR code on final canvas
    // ctx.drawImage(qrCanvas, 0, 0);
    //
    // // Add text on the final canvas
    // ctx.font = '20px Arial';
    // ctx.fillStyle = 'black';
    // ctx.fillText('Your Text Here', 10, this.size - 10); // Adjust position as needed
    //
    // // Add image on the final canvas
    // const img = new Image();
    // img.src = 'public/img/brand/logo-ibotech-white.png'; // Replace with the path to your image
    // img.onload = () => {
    //   ctx.drawImage(img, (this.size - 50) / 2, (this.size - 50) / 2, 50, 50); // Center the image
    // };
  }

  downloadQrCode() {
    window.print();

    // const divElement = document.getElementById('finalCanvas');
    // const qrCanvas = divElement.querySelector('canvas');
    // const canvas = document.getElementById('finalCanvas');
    // const canvas = document.getElementById('finalCanvas');
    //
    // console.log(canvas);
    //
    // const url = canvas.toDataURL('image/png');
    // const link = document.createElement('a');
    // link.href = url;
    // link.download = 'qrcode.png';
    // link.click();
  }

}
